import React from 'react';

import {
    Flex,
    Text,
    Stack,
    Image,
    Container,
    Center
} from '@chakra-ui/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import Plans from '../components/Plans'

import Comunidade from '../assets/pics/comunidade_1.jpg'
import Comunidade2 from '../assets/pics/comunidade.jpg'
import Features from './Features';

const MainContent = () => {
    const { t } = useTranslation();
    return (
        <Container bg='#1d1d1d' maxW='full' p={0} id='about'>
            <Stack direction='column' padding={0} spacing={0} h='100vh'>
                <Stack direction={['column-reverse', 'row-reverse']} h='50%' spacing={0}>
                    <Flex flex={1} justify='center' align='center'>
                        <Flex width={['90%', '70%']} m={0}>
                            <Text lineHeight={[1.5, 2]} fontSize={[11, 20]} color='gray.300' fontFamily='Futura-Medium'>
                                {t("mainFirst")}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flex={1} justifyContent='center'>
                        <Image objectFit='cover' w='100%' src={Comunidade}/>
                    </Flex>
                </Stack>
                <Stack direction={['column-reverse', 'row']} h='50%' spacing={0}>
                    <Flex flex={1} justify='center' align='center'>
                        <Flex width={['90%', '70%']} m={0}>
                            <Text lineHeight={[1.5, 2]} fontSize={[11, 20]} color='gray.300' fontFamily='Futura-Medium'>
                                {t("mainSecond")}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flex={1} justifyContent='center'>
                        <Image objectFit='cover' w='100%' align='80% 20%' src={Comunidade2}/>
                    </Flex>
                </Stack>
            </Stack>
            <Container h='100vh' maxW='full' p={0}>
                <Center pt={[0, 10]} id='plans'>
                    <Text casing='uppercase' color='white' fontFamily='MoonBold' style={{ letterSpacing: 3}} fontSize={50}>{t("plans")}</Text>
                </Center>
                <Plans />
            </Container>
            <Features />
        </Container>
    )
}

export default MainContent;