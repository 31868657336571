import React from "react";

import {
    VStack,
    Text,
    Icon,
    HStack,
    Flex,
} from "@chakra-ui/react";

import Cacifos from "../assets/icons/cacifos.svg";
import Coffee from "../assets/icons/coffee.svg";
import Comunidade from "../assets/icons/comunidade.svg";
import Copa from "../assets/icons/copa.svg";
import Correios from "../assets/icons/correios.svg";
import Lazer from "../assets/icons/lazer.svg";
import Eventos from "../assets/icons/eventos.svg";
import Hours from "../assets/icons/hours.svg";
import MeetingRoom from "../assets/icons/meetingRoom.svg";
import Printer from "../assets/icons/printer.svg";
import VideoCallRoom from "../assets/icons/videoCallRoom.svg";
import Wireless from "../assets/icons/wireless.svg";

import { useTranslation } from "gatsby-plugin-react-i18next";

const Feature = ({Svg, Copy}: {Svg: string, Copy: string}) => {
    const { t } = useTranslation();
    return (
        <VStack height="auto" width={["33vw", "17vw"]} spacing={0} justifyContent="center">
            <Icon mt={["4vh", "8vh"]} mb={5} w={"6vw"} h={"6vw"}>
                <Svg/>
            </Icon>
            <Text color="white" textAlign="center" fontSize={[12, 20]} fontFamily="Futura-Medium">{t(Copy)}</Text>
        </VStack>
    )
}

const Features = () => {

    const obj = [

            {
                Icon: Cacifos,
                Copy: "lockers",
            },
            {
                Icon: Coffee,
                Copy: "coffee",
            },
            {
                Icon: Comunidade,
                Copy: "communityTitle",
            },
            {
                Icon: Copa,
                Copy: "cope",
            },
            {
                Icon: Correios,
                Copy: "mail",
            },
            {
                Icon: Lazer,
                Copy: "fun",
            },
            {
                Icon: Eventos,
                Copy: "events",
            },
            {
                Icon: Hours,
                Copy: "schedule",
            },
            {
                Icon: MeetingRoom,
                Copy: "meetingRoomTitle",
            },
            {
                Icon: Printer,
                Copy: "printer",
            },
            {
                Icon: VideoCallRoom,
                Copy: "callRoom",
            },
            {
                Icon: Wireless,
                Copy: "internet",
            }
    ]

    const { t } = useTranslation();
    return (
        <VStack h="100vh" id="services" spacing={0}>
            <Flex flex={1}>
                <Text casing="uppercase" style={{ letterSpacing: 3}} color="white" fontFamily="MoonBold" fontSize={50} pt={[0,0]}>{t("services")}</Text>
            </Flex>
            <Flex flex={12} justifyContent='center' pb={6}>
                <HStack wrap="wrap"width="80%" justifyContent="space-around">
                    {obj.map((item, index) => {
                        return (
                            <Feature Svg={item.Icon} Copy={item.Copy} key={index} />
                        )
                    })}
                </HStack>
            </Flex>
        </VStack>
    );
};

export default Features;