import * as React from "react"
import { Helmet } from "react-helmet"

import './style.css'
import Header from "../components/Header"
import MainContent from "../components/MainContent"
import Community from "../components/Community"
import Contact from '../components/Contact'
import { graphql } from "gatsby"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Maak Cowork</title>
      </Helmet>
      <Header />
      <MainContent />
      <Community />
      <Contact />
    </>
  )
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default IndexPage
