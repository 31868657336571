import React from 'react';
import GoogleMapReact from 'google-map-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

const isClient = typeof window !== 'undefined';

const Marker = () => <FontAwesomeIcon icon={faLocationDot} size='2x' color='#22b67c' />;

export const GoogleMap = ({lat, lng}: { lat: any, lng: any}) => {
    return (
        <>
            { isClient && (
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAxtSwOw32legHsM7hYBiGjV3M6hA2vn4M' }}
                    defaultCenter={{ lat, lng }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={16}
                >
                    <Marker
                        lat={lat}
                        lng={lng}
                    />
                </GoogleMapReact>
            )}
        </>
    );
}

export default GoogleMap;