import React, { useCallback, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass, { Navigation, Autoplay } from "swiper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"

import {useTranslation} from "gatsby-plugin-react-i18next";

import "swiper/css"
import "swiper/css/navigation"
import "./styles/Plans.css";

import {
    VStack,
    Image,
    Text,
    Flex,
    Show,
    Button,
    HStack,
} from "@chakra-ui/react";

import Dedicated from "../assets/pics/dedicated1.jpg"
import Flexible from "../assets/pics/flexible.jpg"
import Private from "../assets/pics/private.jpg"
import Address from "../assets/pics/address.jpg"
import MeetingRoom from "../assets/pics/meeting.jpg"

const Plan = ({text, type, image, handleLeft, handleRight, showLast, showFirst}: {
    text: string,
    type: string,
    image: string,
    handleLeft: () => void,
    handleRight: () => void,
    showLast: boolean,
    showFirst: boolean,
}) => {
    const {t} = useTranslation();
    return (
        <Flex direction={["column", "row"]} flex={1} justifyContent={["center", "center"]}>
            <Flex flex={1} style={{ position: "relative" }} justifyContent="center">
                {/* <Flex style={{ position: "absolute", top: "45%" }} width="95%" justifyContent="space-between">
                    {
                        showFirst ? <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#0fa187" onClick={handleLeft}/> : <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#22b67c" style={{ opacity: 0 }}/>
                    }
                    {
                        showLast && <FontAwesomeIcon icon={faChevronRight} size="2x" color="#0fa187" onClick={handleRight}/>
                    }
                </Flex> */}
                <Flex flex={1} justifyContent={['center', 'flex-end']}>
                    <Image boxSize={['28vh', '28vw']} objectFit="cover" src={image} overflow="hidden"/>
                </Flex>
            </Flex>
            <Flex flexDir="column" flex={1} pt={[75, 0]}>
                <Text
                    fontSize={[13, 26]}
                    fontFamily="Futura-Bold"
                    textAlign={["center", "left"]}
                    pl={[0, 20]}
                    color="white"
                    pb={4}
                    casing='uppercase'
                    style={{
                        letterSpacing: 3
                    }}
                >
                    {t(type)}
                </Text>
                <Text 
                    fontSize={[10, 20]}
                    fontFamily="Futura-Medium"
                    textAlign={["center", "left"]}
                    pl={[0, 20]}
                    color="white"
                >
                    {t(text)}
                </Text>
            </Flex>
        </Flex>
    )
}

const Plans = () => {
    const {t} = useTranslation();
    const [index, setIndex] = useState<any>(0);
    const [swiperRef, setSwiperRef] = useState<SwiperClass>();

    const handleLeftClick = useCallback(() => {
        if (!swiperRef) return;
        swiperRef.slidePrev();
    }, [swiperRef]);

    const handleRightClick = useCallback(() => {
        if (!swiperRef) return;
        swiperRef.slideNext();
    }, [swiperRef]);

    const plans = [
        {
            img: Dedicated,
            type: "dedicatedTitle",
            text: "dedicated"
        },
        {
            img: Flexible,
            type: "flexibleTitle",
            text: "flexible"
        },
        {
            img: Private,
            type: "privateTitle",
            text: "private"
        },
        {
            img: MeetingRoom,
            type: "meetingRoomTitle",
            text: "meetingRoom"
        },
        {
            img: Address,
            type: "addressTitle",
            text: "address"
        },
    ]
    
    return (
        <VStack h={['80vh',"90vh"]} spacing={0} justifyContent={['space-evenly', 'center']}>
            <Swiper
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: false,
                }}
                onSwiper={setSwiperRef}
                onRealIndexChange={(e) => setIndex(e.activeIndex)}
                modules={[Navigation, Autoplay]}
                style={{ width: "70%" }}
            >
                {plans.map((plan, index) => (
                    <SwiperSlide key={index}>
                        <Plan showFirst={index !== 0} showLast={index !== plans.length-1} handleLeft={handleLeftClick} handleRight={handleRightClick} text={plan.text} type={plan.type} image={plan.img}/>
                    </SwiperSlide>
                ))}
            </Swiper>
            <HStack w="100%" justify="space-evenly">
                <Button
                    left={[0, '3vw']}
                    onClick={() => window.scrollTo({ top: 99999, behavior: "smooth"})}
                    style={{
                        top: 50,
                        zIndex: 10,
                        backgroundColor: "#0fa187",
                    }}
                >
                    <Text
                        color='white'
                        casing='uppercase'
                        fontFamily="Futura-Medium"
                        style={{ letterSpacing: 2 }}
                    >
                        {t("contactUs")}
                    </Text>
                </Button>
                // invisible flex to ocupy space
                <Show above="md">
                    <Flex w={20}/>
                </Show>
            </HStack>
            <Show breakpoint='(min-width: 700px)'>
                <Flex width="98vw" direction="row">
                    <Flex w="50%" style={{ position: "relative" }}>
                        {index !== 0 &&
                            (
                                <Image
                                style={{
                                    position: "absolute",
                                    left: "10vw",
                                    top: "-4vh",
                                    opacity: 0.4
                                }}
                                boxSize={['4vw', '6vw', '10vw']}
                                src={plans[index-1]?.img}
                                objectFit="cover"
                                />
                            )
                        }
                        
                        {index !== plans.length-1 ?
                            (
                                <Image
                                    style={{
                                        alignSelf: "flex-end",
                                        position: "relative",
                                        left: "110%",
                                        top: "-8vw",
                                        opacity: 0.4
                                    }}
                                    boxSize={['4vw', '6vw', '10vw']}
                                    objectFit="cover"
                                    src={plans[index+1]?.img}
                                />
                            )
                            :
                            (
                                // placeholder
                                <Image
                                    style={{
                                        alignSelf: "flex-end",
                                        position: "relative",
                                        left: "110%",
                                        top: "-8vw",
                                        opacity: 0
                                    }}
                                    boxSize={['4vw', '6vw', '10vw']}
                                    objectFit="cover"
                                    src={plans[0]?.img}
                                />
                            )
                        }
                    </Flex>
                </Flex>
            </Show>
        </VStack>
    );
}

export default Plans;