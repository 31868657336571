import React from 'react';

import { Container, VStack, Text, HStack, Image, Stack } from '@chakra-ui/react';

import p1 from '../assets/partnerships/1.jpeg'
import p2 from '../assets/partnerships/2.jpeg'
import p3 from '../assets/partnerships/3.jpeg'
import p4 from '../assets/partnerships/4.jpg'
import p5 from '../assets/partnerships/5.png'
import p6 from '../assets/partnerships/6.png'

import Roadmap from '../assets/icons/roadmap.svg'
import RoadmapEN from '../assets/icons/roadmap_EN.png'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

const Partner = ({ smaller, img, link }: { smaller: boolean, img: string, link: string}) => {
    return (
        <a href={link}>
            { smaller ? (
                <Image
                    w={['16vw', '6vw']}
                    m={['4vw', '2vw']}
                    src={img}
                />
            ) : (
                <Image
                    w={['20vw', '8vw']}
                    m={['4vw', '2vw']}
                    src={img}
                />
            )}
            {/* <Box w={['25vw', '10vw']} h={['10vw', '8vw']} m={['4vw', '2vw']} bg='blackAlpha.300' /> */}
        </a>
    )
}

const partners = [
    {
        img: p1,
        link: 'https://www.balmo.pt'
    },
    {
        img: p2,
        link: 'https://www.dinamo10.net/'
    },
    {
        img: p3,
        link: 'https://www.espacioarroelo.es/'
    },
    {
        img: p4,
        link: 'https://anceu.com/'
    },
    {
        img: p5,
        link: 'https://www.google.com'
    },
    {
        img: p6,
        link: 'https://encostadomonte.com'
    },
]

const Community = () => {
    const { t } = useTranslation();
    const { language } = useI18next();
    return (
        <Container h='100vh' maxW='full' id='partners'>
            <VStack w='100%' h='90%' justifyContent='space-between'>
                <Text pt={50} fontSize={50} casing='uppercase' fontFamily='MoonBold' style={{ letterSpacing: 3}}>{t("community")}</Text>
                <HStack wrap='wrap'width='80%' height='auto' justifyContent='center' pb={'0vh'}>
                        {
                            // run a loop 5 times
                            partners.map((item, i) => {
                                return (
                                    <Partner smaller={i === 5} img={item.img} link={item.link} />
                                )
                            })
                        }
                </HStack>
                <Stack overflowX='scroll' width={['90vw', '80vw']} pt={['3vh', '12vh']}>
                    {language === 'en' ? (
                        <Image
                            src={RoadmapEN}
                            style={{ width: '1200', height: 'auto' }}
                        />
                    ) : (
                        <Roadmap style={{ width: '1200', height: 'auto' }} />
                    )
                    }
                </Stack>
            </VStack>
        </Container>
    );
};

export default Community;