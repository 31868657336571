import React from 'react';

import { Container, HStack, VStack, Text, Input, FormLabel, FormControl, Button, Textarea, Flex, Stack } from '@chakra-ui/react';
import { GoogleMap } from './GoogleMap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    return (
        <Container maxW='full' h='100vh' id='contact'>
            <VStack h='100%'>
                <Text pt={50} casing='uppercase' fontFamily='MoonBold' fontSize={50} pb={[10 ,100]} style={{ letterSpacing: 3}}>{t("contacts")}</Text>
                <Stack direction={['column', 'row']} w='90%' h='100%'>
                    <VStack w={['100%', '50%']} h='100%'>
                        <Flex w={['85vw', 'auto']} h='100%' justifyContent='flex-start'>
                            <form style={{ height: '90%' }} method="post" action='https://getform.io/f/d4d24028-3f68-4d73-ae15-bf92eb351694'>
                                <Flex direction='column' justifyContent='space-between' h='100%'>
                                    <Flex direction='column'>
                                        <FormControl isRequired>
                                            <FormLabel>{`${t("name")}:`}</FormLabel>
                                            <Input w={['80vw','20vw']} type='text' borderColor='gray.400' name='name' style={{
                                                    marginBottom: '6vh',
                                                    borderWidth: 1,
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>{`${t("email")}:`}</FormLabel>
                                            <Input w={['80vw','20vw']} type='email' name='email' borderColor='gray.400' style={{
                                                    marginBottom: '6vh',
                                                    borderWidth: 1,
                                                }}
                                            />
                                        </FormControl> 
                                        <FormControl isRequired>
                                            <FormLabel>{`${t("message")}:`}</FormLabel>
                                            <Textarea w={['80vw','20vw']} name='message'  borderColor='gray.400' style={{
                                                    marginBottom: '6vh',
                                                    borderWidth: 1,
                                                }}
                                            />
                                        </FormControl>
                                    </Flex>
                                    <Flex>
                                        <Button style={{ borderColor: '#22b67c', borderWidth: 1, marginBottom: 50 }} type='submit'>{t("send")}</Button>
                                    </Flex>
                                </Flex>
                            </form>
                        </Flex>
                    </VStack>
                    <VStack w={['100%', '50%']} h='90%' alignSelf='flex-start' pb={10}>
                        <GoogleMap lat={41.7615016285571} lng={-8.582190827085107} />
                        <Text as='b' style={{ paddingTop: 10 }} fontSize={20} alignSelf='flex-start' fontFamily='Futura-Medium'>{t("addressTitleBody")}</Text>
                        <Text as='b' fontSize={20} alignSelf='flex-start' fontFamily='Futura-Medium'>{t("addressBody")}</Text>
                        <Text as='b' style={{ paddingTop: 10 }} casing='capitalize' fontSize={20} alignSelf='flex-start' fontFamily='Futura-Medium'>{t("contacts")}</Text>
                        <Text fontSize={20} alignSelf='flex-start' fontFamily='Futura-Medium'>+351 966 314 169*</Text>
                        <Text fontSize={20} alignSelf='flex-start' fontFamily='Futura-Medium'>hello@maak.pt</Text>
                        <Text fontSize={10} alignSelf='flex-start' fontFamily='Futura-Medium'>{t("callDisclaimer")}</Text>
                        <Flex flex={1} justifyContent='flex-start' w='100%' style={{ paddingTop: 10 }}>
                            <Flex style={{ paddingRight: 20 }}>
                                <a href='https://www.instagram.com/maak.cowork/'>
                                    <HStack>
                                        <FontAwesomeIcon size='xl' icon={faInstagram} />
                                        <Text style={{ paddingLeft: 5 }}>Instagram</Text>
                                    </HStack>
                                </a>
                            </Flex>
                            <Flex>
                                <a href='https://www.facebook.com/profile.php?id=100087510456396'>
                                    <HStack>
                                        <FontAwesomeIcon size='xl' icon={faFacebook} />
                                        <Text style={{ paddingLeft: 5 }}>Facebook</Text>
                                    </HStack>
                                </a>
                            </Flex>
                        </Flex>
                    </VStack>
                </Stack>
            </VStack>
        </Container>
    );
}

export default Contact;